import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, Title, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import BatchService from 'Api/batchService';
import DocumentService from 'Api/documentService';
import TableWithFilters from 'Components/data-table/TableWithFilters';
import { Assessment } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { constructPostDataForFileUpload } from 'Src/pages/documents/common';
import { BatchType } from 'Types/batchTypes';
import {
  DocumentDataType,
  UploadFileDetailType,
  SnapshotRequestType,
} from 'Types/docTypes';
import { showErrorNotification } from 'Utils/notifications';
import { MBToBytes } from 'Utils/transform';
import debounce from 'lodash/debounce';

import getDocumentsColumnConfig from './column-configs';
import AddDocumentDrawer from './components/AddDocumentDrawer';
import PreviewDocumentDrawer from './components/PreviewDocumentDrawer';

type DocumentsProps = {
  id: number;
  batch_id: number;
  filename: string;
};

const extractDocuments = (data: BatchType[]): DocumentDataType[] => {
  return data.reduce((documentsList: DocumentDataType[], batch: BatchType) => {
    return [...documentsList, ...batch.documents];
  }, []);
};

const Documents: React.FC = () => {
  const { batchId } = useParams();
  const [openDrawer, handleAddDocumentDrawer] = useDisclosure();
  const [openDocumentPreviewDrawer, handlePreviewDocumentDrawer] =
    useDisclosure();
  const [previewDocumentDetails, setPreviewDocumentDetails] =
    useState<DocumentDataType | null>(null);
  const [documentsList, setDocumentsList] = useState<DocumentDataType[] | []>(
    []
  );
  const [uploadedFile, setUploadedFile] = useState<UploadFileDetailType | null>(
    null
  );
  const [fileUploadingLoader, handleFileUploadingLoading] = useLoading(true);
  const navigate = useNavigate();
  const [loading, handleLoader] = useLoading(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filteredDocuments, setFilteredDocuments] = useState<
    DocumentDataType[] | []
  >([]);
  const [totalBatches, setTotalBatches] = useState<number>(0);

  const fetchDocuments = async () => {
    try {
      handleLoader.start();
      const { data } = await BatchService.getBatches({
        filters: { page: currentPage },
      });
      const results = data.results || [];
      const orgId = results.length > 0 ? results[0].org_id : null;
      setTotalBatches(data.count);
      if (!orgId) return;
      let documents = extractDocuments(results);
      documents = documents.map((doc) => ({ ...doc, org_id: orgId }));
      if (documents && documents.length > 0) {
        if (batchId) {
          documents = documents.filter(
            (doc: DocumentsProps) => doc.batch_id === parseInt(batchId)
          );
        }
        setDocumentsList(documents);
        setFilteredDocuments(documents);
      }
      handleFileUploadingLoading.stop();
    } catch (e) {
      showErrorNotification('Something went wrong while fetching documents');
    } finally {
      handleLoader.stop();
    }
  };

  const createDocumentSnapshot = async () => {
    if (uploadedFile) {
      try {
        handleFileUploadingLoading.start();
        const postData = constructPostDataForFileUpload(uploadedFile);
        const response = await DocumentService.createDocuments(postData);
        if (response && response.data) {
          const { documents } = response.data;
          // As of now, we only supports the sigle file upload from FE
          const { snapshot } = documents[0];
          upload(uploadedFile.file, snapshot);
        }
      } catch (e) {
        handleFileUploadingLoading.stop();
        showErrorNotification('Something went wrong while uploading document');
      } finally {
        setUploadedFile(null);
      }
    }
  };

  const deleteDocument = async (id: number) => {
    try {
      handleLoader.start();
      await DocumentService.deleteDocuments(id);
    } catch (e) {
      showErrorNotification('Something went wrong while deleting document');
    } finally {
      handleLoader.stop();
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (uploadedFile) createDocumentSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const upload = async (file: File, snapshot: SnapshotRequestType) => {
    const { fields, id: snapShotId, url }: SnapshotRequestType = snapshot;
    try {
      if (!file) return;
      notifications.show({
        loading: true,
        title: 'File Uploading',
        message: 'Please wait...',
        autoClose: 3000,
        withCloseButton: true,
      });

      if (snapShotId) {
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        formData.append('file', file);
        // const awsResponse = await axios.put(data.file, formData.file);
        const awsResponse = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (awsResponse) {
          await DocumentService.bulkUploadFinish([snapShotId]);
          fetchDocuments();
          notifications.cleanQueue();
          notifications.show({
            color: 'teal',
            title: 'Success',
            message: 'File uploaded successfully.',
            icon: <IconCheck style={{ width: 18, height: 18 }} />,
            loading: false,
            autoClose: 2000,
          });
        }
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red',
      });
    }
    handleFileUploadingLoading.stop();
  };

  const onFileSelect = (file: File | null, document: DocumentDataType) => {
    if (!file || !document) return;
    if (file) {
      handlePreviewDocumentDrawer.close();
      setPreviewDocumentDetails(null);
      const { MAX_FILE_SIZE } = Assessment;
      if (file.size > MBToBytes(MAX_FILE_SIZE)) {
        return showErrorNotification(
          `File size should not exceed ${MAX_FILE_SIZE} MB.`
        );
      }
      const fileDetail = {
        file,
        batchId: document.batch_id,
        docId: document.id,
        name: file.name,
        orgId: document?.org_id,
      };
      return setUploadedFile(fileDetail);
    }
    showErrorNotification('Something went wrong, please try again.');
  };

  const redirectToAssessment = (document: DocumentDataType) => {
    navigate(`/assessment/${document.batch_id}/${document.id}`);
  };

  const searchDocuments = async (value: string) => {
    // TODO: Replace this with API call once BE supports search
    try {
      const filteredDocuments = documentsList.filter((doc) =>
        doc.filename.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDocuments(filteredDocuments);
    } catch (e) {
      showErrorNotification('Error while searching documents');
    }
  };

  const onSearch = debounce(async (value: string) => {
    setCurrentPage(1);
    searchDocuments(value);
  }, 300);

  return (
    <React.Fragment>
      <Flex w="100%" h="90vh" direction="column">
        <Flex justify="space-between">
          <Title order={4}>Documents</Title>
          <Button
            onClick={() => handleAddDocumentDrawer.open()}
            size="sm"
            variant="outline"
          >
            Add New
          </Button>
        </Flex>
        <Flex flex={1} direction="column" py="md" style={{ overflowY: 'auto' }}>
          <TableWithFilters
            loading={loading || fileUploadingLoader}
            records={filteredDocuments}
            columns={getDocumentsColumnConfig({
              setPreviewDocumentDetails,
              handlePreviewDocumentDrawer,
              onFileSelect,
              navigate,
              deleteDocument,
              fetchDocuments,
            })}
            totalRecords={totalBatches}
            page={currentPage}
            onPageChange={(currentPage: number) => {
              setCurrentPage(currentPage);
            }}
            showSearch
            onSearch={onSearch}
          />
        </Flex>
      </Flex>
      <AddDocumentDrawer
        openAddDocumentDrawer={openDrawer}
        handleAddDocumentDrawer={handleAddDocumentDrawer}
        batchId={batchId ? parseInt(batchId) : undefined}
        uploadFile={upload}
        refresh={fetchDocuments}
      />
      <PreviewDocumentDrawer
        documentsList={documentsList}
        openDocumentPreview={openDocumentPreviewDrawer}
        handlePreviewDocument={handlePreviewDocumentDrawer}
        documentDetails={previewDocumentDetails}
        redirectToAssessment={redirectToAssessment}
        onFileSelect={onFileSelect}
      />
    </React.Fragment>
  );
};

export default Documents;
