import React, { useState } from 'react';

import { SegmentedControl, SegmentedControlItem } from '@mantine/core';
import { DocumentSnapshotType } from 'Types/docTypes';

type VersionsSwitchProps = {
  primarySnapshot?: DocumentSnapshotType;
  versions: (string | SegmentedControlItem)[];
  onChange: (value: string) => void;
};

const VersionsSwitch: React.FC<VersionsSwitchProps> = ({
  versions,
  onChange,
  primarySnapshot,
}) => {
  const [value, setValue] = useState(
    primarySnapshot && primarySnapshot?.id.toString()
  );
  return (
    <SegmentedControl
      value={value}
      onChange={(value) => {
        setValue(value);
        onChange(value);
      }}
      data={versions}
    />
  );
};

export default VersionsSwitch;
