import React, { useEffect, useState } from 'react';

import {
  Collapse,
  Stack,
  Spoiler,
  Text,
  Group,
  Select,
  Tooltip,
  Button,
  Box,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMessage } from '@tabler/icons-react';
import ruleInprogressIcon from 'Assets/rule-in-progress.svg';
import Textarea from 'Components/text-area/Textarea';
import { RuleEvalStatus } from 'Constants/index';
import useRuleStatus from 'Src/hooks/useRuleStatus';
import { RuleEvalType, SelectStatusProps } from 'Types/ruleTypes';
import { getRuleEvalStatusColor } from 'Utils/transform';

import DisplayRetriggerAction from './RetriggerAction';

interface RuleDetailsProps {
  cardOpened: boolean;
  currentRuleEval: RuleEvalType;
  onSelectStatus: (config: SelectStatusProps) => void;
  confirmRetriggerRule: () => void;
  reTriggerRule: () => void;
  openStatusPrompt: boolean;
  closeConfirmRetriggerModal: () => void;
  latestComment?: string | null;
  viewMode?: boolean;
  handleNavigateToComment?: () => void;
}

const DisplayRuleInProgress = () => {
  return (
    <Tooltip label="Rule execution is in prorgess">
      <img src={ruleInprogressIcon} width="20px" height="20px" />
    </Tooltip>
  );
};

const RuleDetails: React.FC<RuleDetailsProps> = ({
  cardOpened,
  currentRuleEval,
  onSelectStatus,
  confirmRetriggerRule,
  reTriggerRule,
  openStatusPrompt,
  closeConfirmRetriggerModal,
  latestComment,
  viewMode = false,
  handleNavigateToComment,
}) => {
  const theme = useMantineTheme();
  const {
    isRuleInProgress,
    showSelectStatusManually,
    isRuleManuallyOverridden,
    rationales,
  } = useRuleStatus({ ruleEval: currentRuleEval });
  const rationale = rationales ? rationales.join(' ') : undefined;
  const latestMessage = latestComment || rationale;
  const [comment, setComment] = useState<string | undefined>(latestMessage);
  const [statusChanged, handleStatusChanged] = useDisclosure(false);
  const currentStatus = currentRuleEval.status?.id.toString();
  const [status, setStatus] = useState<string | null>(currentStatus);
  const [errorMessage, setErrorMessage] = useState('');
  const { primary: primaryColor, seconday: secondayColor } =
    getRuleEvalStatusColor(theme, currentRuleEval.status?.id.toString());

  useEffect(() => {
    if (!latestComment) return;
    setComment(latestComment);
  }, [latestComment]);

  const handleStatusChange = (status: string | null) => {
    setComment('');
    setStatus(status);
    handleStatusChanged.toggle();
  };

  const updateStatus = () => {
    if (statusChanged && comment) {
      onSelectStatus({
        status,
        newComment: comment,
        onCallback: () => handleStatusChanged.close(),
      });
    } else {
      setErrorMessage('Comment is required when changing status');
    }
  };

  const resetRuleEval = () => {
    setStatus(currentStatus);
    setComment(latestComment || rationale);
    setErrorMessage('');
  };

  return (
    <Collapse in={cardOpened} ml="xl">
      <Stack ml="sm" mt="xs" gap="sm">
        <Spoiler
          maxHeight={50}
          showLabel={
            <Text c="blue" size="sm">
              Show more
            </Text>
          }
          hideLabel={
            <Text c="blue" size="sm">
              Show less
            </Text>
          }
          fz={16}
        >
          <Text size="xs">{currentRuleEval.rule.description}</Text>
        </Spoiler>
        {(comment || statusChanged) && (
          <Stack gap="0">
            <Textarea
              value={comment || ''}
              disabled={!statusChanged}
              variant="filled"
              showAsComment={!statusChanged}
              showRedirectToComment={!viewMode && Boolean(latestComment)}
              rightSection={
                !viewMode &&
                latestComment && (
                  <IconMessage
                    size="20"
                    style={{ cursor: 'pointer' }}
                    onClick={handleNavigateToComment}
                  />
                )
              }
              maxRows={3}
              onChange={(e) => {
                const value = e.target.value;
                setComment(value);
                setErrorMessage(
                  !value ? 'Comment is required when changing status' : ''
                );
              }}
              error={errorMessage}
            />
            {statusChanged && (
              <Group ml="sm" gap="0" mt="sm" justify="end">
                <Tooltip label="Comment is required when changing status">
                  <Button variant="filled" size="xs" onClick={updateStatus}>
                    Save
                  </Button>
                </Tooltip>
                <Button
                  variant="white"
                  size="xs"
                  onClick={() => {
                    resetRuleEval();
                    handleStatusChanged.close();
                  }}
                >
                  Cancel
                </Button>
              </Group>
            )}
          </Stack>
        )}
        {!viewMode ? (
          <Group justify="space-between">
            <Group gap="6" w="50%">
              <Select
                size="xs"
                placeholder="Select status"
                onChange={handleStatusChange}
                data={Object.keys(RuleEvalStatus).map((status) => {
                  const key = status as keyof typeof RuleEvalStatus;
                  return {
                    value: key,
                    label: RuleEvalStatus[key],
                  };
                })}
                value={status}
              />
              {showSelectStatusManually && (
                <Text size="xs" c="dimmed">
                  Select Status manually
                </Text>
              )}
              {!showSelectStatusManually && isRuleManuallyOverridden && (
                <Text size="xs" c="dimmed">
                  Manually selected
                </Text>
              )}
            </Group>
            {isRuleInProgress && <DisplayRuleInProgress />}
            {!isRuleInProgress && (
              <DisplayRetriggerAction
                open={confirmRetriggerRule}
                reTriggerRule={reTriggerRule}
                openStatusPrompt={openStatusPrompt}
                closeConfirmRetriggerModal={closeConfirmRetriggerModal}
              />
            )}
          </Group>
        ) : (
          <Box
            bg={secondayColor}
            w="max-content"
            px="sm"
            py="4"
            style={{ borderRadius: '4px' }}
          >
            <Text size="xs" c={primaryColor} fw="500">
              {currentRuleEval.status.name}
            </Text>
          </Box>
        )}
      </Stack>
    </Collapse>
  );
};

export default RuleDetails;
