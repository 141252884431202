import axios from 'axios';
import { startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MutableRefObject } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Flex,
  Grid,
  Group,
  Menu,
  Popover,
  Text,
  Tooltip,
  Stack,
  Progress,
  Input,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconFilter,
  IconRefresh,
  IconSearch,
  IconSortAscendingSmallBig,
  IconSortDeacendingSmallBig,
  IconUpload,
} from '@tabler/icons-react';
import AssessmentService from 'Api/assessmentService';
import DocumentService from 'Api/documentService';
import {
  Assessment,
  RuleEvalStatus,
  RuleEvalStatusCodes,
} from 'Constants/index';
import { AssessmentTabs } from 'Constants/index';
import { constructPostDataForFileUpload } from 'Src/pages/documents/common';
import { AssessmentDetailType, ReportType } from 'Types/assessmentTypes';
import { DocumentDataType } from 'Types/docTypes';
import { RuleEvalType } from 'Types/ruleTypes';
import {
  clearNotificationsQueue,
  showErrorNotification,
  showLoadingNotification,
  showSuccessNotification,
} from 'Utils/notifications';
import { MBToBytes } from 'Utils/transform';

import classes from '../../../Assessment.module.scss';
import DisplayRuleEvals from '../../../components/DisplayRuleEvals';
import AssessmentSetup from '../overview/AssessmentSetup';

enum EvaluationMode {
  LOADING = 'loading',
  SHOW = 'show',
  CREATE = 'create',
}

type AssessmentOverviewProps = {
  assessmentData: AssessmentDetailType | null;
  documentData: DocumentDataType | null;
  reRunAssessment: (data: AssessmentDetailType) => void;
  fetchDocument: () => Promise<DocumentDataType | null>;
  changeMainTab: (tab: string) => void;
  setActiveTab: (tab: string) => void;
  ruleEvalRefs: MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
};

type FilterControlsType = {
  searchTerm: string;
  activeFilterStatus: number | null;
};

type CompletionProgressBarType = {
  label: string;
  percentage: number;
};
const AssessmentOverview: React.FC<AssessmentOverviewProps> = ({
  assessmentData,
  documentData,
  reRunAssessment,
  fetchDocument,
  changeMainTab,
  setActiveTab,
}) => {
  const theme = useMantineTheme();
  const [assessmentDetail, setAssessmentDetail] =
    useState<AssessmentDetailType | null>(null);
  const [evaluationMode, setEvaluationMode] = useState<EvaluationMode>(
    EvaluationMode.LOADING
  );

  const [report, setReport] = useState<ReportType | null>(null);
  const [filteredRuleEvals, setFilteredRuleEvals] = useState<
    RuleEvalType[] | []
  >([]);
  const [openStatusPrompt, openStatusPromptHandler] = useDisclosure(false);
  const [filterControls, setFilterControls] = useState<FilterControlsType>({
    searchTerm: '',
    activeFilterStatus: null,
  });
  const [activeSort, setActiveSort] = useState<'asc' | 'desc'>('asc');
  const [completionProgressBar, setCompletionProgressBar] =
    useState<CompletionProgressBarType>({ label: '', percentage: 0 });

  useEffect(() => {
    if (assessmentData && assessmentData.report_id && assessmentData.id) {
      fetchAssessmentDetail();
    } else {
      setEvaluationMode(EvaluationMode.CREATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentData]);

  useEffect(() => {
    if (assessmentDetail) setEvaluationMode(EvaluationMode.SHOW);
  }, [assessmentDetail]);

  useEffect(() => {
    if (!assessmentDetail?.report?.rule_evals) return;
    calculateLoadingPercentage(assessmentDetail);
  }, [assessmentDetail]);

  useEffect(() => {
    let filteredEvals = report?.rule_evals || [];
    const { searchTerm, activeFilterStatus } = filterControls;
    if (activeFilterStatus) {
      filteredEvals = filteredEvals.filter(
        (ruleEval) => ruleEval.status.id === activeFilterStatus
      );
    }
    if (searchTerm && searchTerm !== '') {
      filteredEvals = filteredEvals.filter((ruleEval) =>
        ruleEval.rule.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    const sortOrderMultiplier = activeSort === 'asc' ? 1 : -1;
    filteredEvals = filteredEvals.sort(
      (a, b) => a.rule.title.localeCompare(b.rule.title) * sortOrderMultiplier
    );
    setFilteredRuleEvals(filteredEvals);
  }, [report, filterControls, activeSort]);

  const calculateLoadingPercentage = (
    assessmentDetail: AssessmentDetailType
  ) => {
    if (!assessmentDetail?.report?.rule_evals) return;
    const { rule_evals: ruleEvals } = assessmentDetail.report;
    const totalRuleEvals = ruleEvals.length;
    const pendingRuleEvals = ruleEvals.filter(
      (item: RuleEvalType) =>
        item.status.id === Number(RuleEvalStatusCodes.PENDING)
    );
    const totalPendingRuleEvals = pendingRuleEvals.length;

    const completionPercentage = Math.abs(
      100 - Math.round((totalPendingRuleEvals / totalRuleEvals) * 100)
    );
    setCompletionProgressBar({
      label:
        totalPendingRuleEvals === 0
          ? `${totalRuleEvals}/${totalRuleEvals} completed`
          : `${totalPendingRuleEvals}/${totalRuleEvals} pending`,
      percentage: completionPercentage,
    });
  };

  const quietRefresh = async () => {
    if (!assessmentData) return;
    const { data } = await AssessmentService.getAssessmentsById(
      assessmentData.id
    );
    if (data) {
      setAssessmentDetail(data);
      if (data.report) {
        setReport(data.report);
      }
    }
  };

  const fetchAssessmentDetail = async () => {
    try {
      setEvaluationMode(EvaluationMode.LOADING);
      const assessmentId = assessmentData?.id;
      if (!assessmentId)
        return showErrorNotification('Invalid Assessment data');
      const response = await AssessmentService.getAssessmentsById(assessmentId);
      if (response) {
        const { data } = response;
        setAssessmentDetail(data);
        if (data.report) {
          setReport(data.report);
        }
      }
    } catch (error) {
      showErrorNotification(
        'Something went wrong while fetching assessment detail'
      );
    }
  };

  const uploadNewSnapshot = async (file: File) => {
    if (!documentData || !assessmentDetail) return;
    const docId = documentData.id;
    const fileName = documentData.filename;
    const batchId = assessmentDetail.batch_id;
    const orgId = assessmentDetail.org_id;
    const postData = constructPostDataForFileUpload({
      docId,
      name: fileName,
      file,
      batchId,
      orgId,
    });
    const response = await DocumentService.createDocuments(postData);
    const documents = response.data?.documents ?? [];
    const snapshot = documents[0]?.snapshot ?? {};
    const { fields, id: snapShotId, url } = snapshot;

    try {
      if (!file) return;
      showLoadingNotification('File Uploading', 'Please wait...');

      if (snapShotId) {
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        formData.append('file', file);
        const awsResponse = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (awsResponse) {
          await DocumentService.bulkUploadFinish([snapShotId]);

          const newDocData = await fetchDocument();
          if (newDocData) {
            const { favorite_snapshot } = newDocData;
            if (favorite_snapshot && favorite_snapshot.id) {
              changeMainTab('assessment');
              clearNotificationsQueue();
              return showSuccessNotification('File updated successfully.');
            }
          }
          throw new Error('Something went wrong while updating the snapshot');
        }
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  };

  if (evaluationMode === EvaluationMode.CREATE) {
    return (
      <AssessmentSetup
        goToRuleSetup={() => changeMainTab(AssessmentTabs.SETUP_TAB)}
      />
    );
  }

  return (
    <Box
      pt={'md'}
      style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'scroll',
        width: '100%',
      }}
    >
      <Box p="lg" pt={0} w="100%">
        <Box mb={100}>
          <Group align="center" mb={'md'} w="100%">
            <Group gap="xs" style={{ flexGrow: 1 }}>
              <Input
                size="sm"
                rightSectionPointerEvents="all"
                w="100%"
                placeholder="Search by rule"
                leftSection={<IconSearch stroke={1.5} />}
                value={filterControls.searchTerm || ''}
                onChange={({ target }) =>
                  setFilterControls((prev) => ({
                    ...prev,
                    searchTerm: target.value,
                  }))
                }
              />
            </Group>
            <Group gap="md">
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <ActionIcon
                    size="md"
                    variant="transparent"
                    aria-label="Filter"
                  >
                    {filterControls.activeFilterStatus ? (
                      <IconFilter />
                    ) : (
                      <IconFilter color="darkgrey" />
                    )}
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>Filter by Status</Menu.Label>
                  {Object.keys(RuleEvalStatus).map((status) => {
                    const key = status as keyof typeof RuleEvalStatus;
                    return (
                      <Menu.Item
                        key={key}
                        style={
                          filterControls.activeFilterStatus === parseInt(key)
                            ? {
                                background: 'ghostwhite',
                                fontWeight: 500,
                              }
                            : {}
                        }
                        onClick={() =>
                          setFilterControls((prev) => ({
                            ...prev,
                            activeFilterStatus:
                              prev.activeFilterStatus === parseInt(key)
                                ? null
                                : parseInt(key),
                          }))
                        }
                      >
                        {startCase(RuleEvalStatus[key].toLowerCase())}
                      </Menu.Item>
                    );
                  })}
                </Menu.Dropdown>
              </Menu>
              {activeSort === 'desc' && (
                <Tooltip label="Sort Ascending">
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    aria-label="Sort ascending"
                    onClick={() => setActiveSort('asc')}
                  >
                    <IconSortAscendingSmallBig />
                  </ActionIcon>
                </Tooltip>
              )}
              {activeSort === 'asc' && (
                <Tooltip label="Sort Descending">
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    aria-label="Sort descending"
                    onClick={() => setActiveSort('desc')}
                  >
                    <IconSortDeacendingSmallBig />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip label="Fetch latest evaluations">
                <ActionIcon variant="subtle" size="md">
                  <IconRefresh onClick={fetchAssessmentDetail} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
          <Stack gap="md">
            <React.Fragment>
              {evaluationMode === EvaluationMode.SHOW && (
                <Flex justify={'flex-end'}>
                  <Stack gap="0">
                    <Text mb={'0.25rem'} size="xs">
                      {completionProgressBar.label}
                    </Text>
                    <Progress
                      value={completionProgressBar.percentage}
                      size={'xs'}
                      color="#414141"
                    />
                  </Stack>
                </Flex>
              )}
              <DisplayRuleEvals
                ruleEvals={filteredRuleEvals}
                loading={evaluationMode === EvaluationMode.LOADING}
                refreshAssessmentDetail={quietRefresh}
                snapshotId={assessmentDetail?.document_snapshot_id}
                viewMode={false}
                setActiveTab={setActiveTab}
              />
              {evaluationMode === EvaluationMode.SHOW && (
                <Box className={classes.floatingButtonContainer}>
                  <Grid gutter={1}>
                    <Grid.Col span={9}>
                      <Popover
                        width={300}
                        withArrow
                        shadow="md"
                        opened={openStatusPrompt}
                        position="top"
                        keepMounted={false}
                      >
                        <Popover.Target>
                          <Button
                            fullWidth
                            color={theme.colors.tertiary[5]}
                            size="large"
                            onClick={openStatusPromptHandler.open}
                          >
                            Re-run Assessment
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Text ta="center" size="sm">
                            This will re-evaluate all the rules and reset the
                            status. <br />
                            Are you sure you want to Re run Assessments
                          </Text>
                          <Flex justify="center" mt="sm">
                            <Button
                              onClick={async () => {
                                openStatusPromptHandler.close();
                                try {
                                  if (assessmentDetail) {
                                    reRunAssessment(assessmentDetail);
                                  }
                                } catch (e) {
                                  showErrorNotification(
                                    'Something went wrong while re-running assessment'
                                  );
                                }
                              }}
                              size="compact-sm"
                            >
                              Yes
                            </Button>
                            <Button
                              ml="xs"
                              variant="transparent"
                              onClick={openStatusPromptHandler.close}
                              size="compact-sm"
                            >
                              No
                            </Button>
                          </Flex>
                        </Popover.Dropdown>
                      </Popover>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Tooltip label="Upload new snapshot">
                        <FileButton
                          onChange={(file) => {
                            if (file) {
                              const { MAX_FILE_SIZE } = Assessment;
                              if (file.size > MBToBytes(MAX_FILE_SIZE)) {
                                return showErrorNotification(
                                  `File size should not exceed ${MAX_FILE_SIZE} MB.`
                                );
                              }
                              return uploadNewSnapshot(file);
                            }
                            showErrorNotification(
                              'Something went wrong, please try again.'
                            );
                          }}
                          accept="pdf"
                        >
                          {(props) => (
                            <Button
                              leftSection={<IconUpload size={13} />}
                              variant="outline"
                              size="large"
                              fullWidth
                              {...props}
                            >
                              Upload
                            </Button>
                          )}
                        </FileButton>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                </Box>
              )}
            </React.Fragment>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AssessmentOverview;
