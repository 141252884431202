import {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDotsVertical, IconNotebook, IconShare } from '@tabler/icons-react';
import AssessmentService from 'Api/assessmentService';
import DocumentService from 'Api/documentService';
import SnapshotService from 'Api/snapshotService';
import FileViewer from 'Components/file-viewer/FileViewer';
import IRNavbar from 'Components/nav-bar/IRNavbar';
import { Mode } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { DocumentDataType, DocumentSnapshotType } from 'Types/docTypes';
import { FileViwerMode } from 'Types/fileViewerTypes';
import { TextCoords } from 'Types/fileViewerTypes';
import { RuleEvalType, RuleIdWithResetStatus } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import NotFound from '../not-found/NotFound';
import classes from './Assessment.module.scss';
import BatchList from './components/BatchLists';
import CompareSnapshotModal from './components/CompareSnapshotsModal';
import SelectSnapshotVersions from './components/SelectSnapshotVersions';
import TabsContainer from './components/TabsContainer';

const MoreMenu = () => {
  return (
    <Menu width={200} shadow="md">
      <Menu.Target>
        <ActionIcon variant="transparent">
          <IconDotsVertical size={20} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconShare size={15} />}>Share</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const Assessment: React.FC = () => {
  const { batchId, docId } = useParams<{
    batchId: string;
    docId: string;
  }>();
  const [documentData, setDocumentData] = useState<DocumentDataType | null>(
    null
  );
  const [assessmentData, setAssessmentData] =
    useState<AssessmentDetailType | null>(null);
  const navigate = useNavigate();
  const [loading, handleLoading] = useLoading(true);
  const [textCoords, setTextCoords] = useState<TextCoords | null>(null);
  const [snapshotVersions, setSnapshotVersions] = useState<
    DocumentSnapshotType[] | []
  >([]);
  const [showCompareModal, setShowCompareModal] = useDisclosure(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState<
    DocumentSnapshotType | undefined
  >(undefined);
  const [showComments, toggleComments] = useState<boolean>(false);

  const fetchAssessment = async (
    latestSnapshotId: number,
    updateState: Dispatch<
      SetStateAction<AssessmentDetailType | null>
    > | null = setAssessmentData
  ) => {
    try {
      if (docId === undefined || batchId === undefined) {
        return;
      }
      const { data: assessmentResponse } =
        await AssessmentService.getAssessmentByInfo(
          parseInt(batchId),
          parseInt(docId),
          latestSnapshotId
        );
      // console.log(assessmentResponse, 'assessmentResponse');
      if (assessmentResponse && assessmentResponse?.results) {
        const data = assessmentResponse.results[0];
        if (updateState) updateState(data);
        return data;
      }
    } catch (err: any) {
      showErrorNotification(err.message);
    }
  };

  const fetchDocument =
    useCallback(async (): Promise<DocumentDataType | null> => {
      if (!batchId || !docId) return null;
      const { data: docResponse } = await DocumentService.getDocumentsById(
        parseInt(docId)
      );
      // console.log(docResponse, 'docResponse');
      if (docResponse) {
        const { favorite_snapshot } = docResponse;
        if (favorite_snapshot) {
          await fetchAssessment(favorite_snapshot.id);
          setDocumentData(docResponse);
        } else {
          showErrorNotification('Snapshot not found');
        }
      }
      handleLoading.stop();
      return docResponse;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchId, docId]);

  const reRunAssessment = async (assessmentDetail: AssessmentDetailType) => {
    if (documentData === null) return;
    try {
      showInfoNotification(
        'Re running the assessment',
        'Evaluation will be updated soon...'
      );
      const ruleEvals = assessmentDetail?.report.rule_evals;
      const rulesToSend: RuleIdWithResetStatus = ruleEvals.reduce(
        (acc: RuleIdWithResetStatus, ruleEval: RuleEvalType) => {
          acc[ruleEval.rule.id] = { reset_status: true };
          return acc;
        },
        {} as RuleIdWithResetStatus
      );

      const { data } = await AssessmentService.reinitializePolicyEvals(
        assessmentDetail.id,
        rulesToSend
      );
      if (data && data.id) {
        setAssessmentData(data);
      }
    } catch (err: any) {
      showErrorNotification('Something went wrong while re-running assessment');
    }
  };

  const refetchAssessment = () => {
    fetchDocument();
    fetchSnapshotVersions();
  };

  useEffect(() => {
    refetchAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentData?.favorite_snapshot?.id) {
      fetchAssessment(documentData?.favorite_snapshot?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData?.favorite_snapshot?.id]);

  useEffect(() => {
    if (selectedSnapshot) setShowCompareModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSnapshot]);

  const fetchSnapshotVersions = async () => {
    try {
      if (!docId) return;
      const { data } = await SnapshotService.getSnapshotsByDocId(
        parseInt(docId)
      );
      setSnapshotVersions(data.data);
    } catch (err) {
      showErrorNotification('Failed to fetch snapshot versions');
    }
  };

  const onSnapshotPreviewClose = () => {
    setSelectedSnapshot(undefined);
    setShowCompareModal.close();
  };

  return (
    <AppShell
      header={{ height: 74 }}
      navbar={{
        width: 230,
        breakpoint: 'lg',
      }}
    >
      <AppShell.Header>
        <IRNavbar />
      </AppShell.Header>
      {loading && (
        <AppShell.Main className={classes.mainBody} pos="relative">
          <LoadingOverlay
            zIndex={1}
            visible={loading}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'pink', type: 'bars' }}
          />
        </AppShell.Main>
      )}
      {documentData && documentData.favorite_snapshot ? (
        <>
          <AppShell.Navbar>
            <AppShell.Section grow>
              <BatchList documentData={documentData} />
            </AppShell.Section>
            <AppShell.Section></AppShell.Section>
          </AppShell.Navbar>
          <AppShell.Main className={classes.mainBody}>
            <Grid gutter={0}>
              <Grid.Col className={classes.compareBox} span={6}>
                <Box py="xs" px="xs">
                  <Flex align="center" justify="space-between">
                    <Group gap="xs" wrap={'nowrap'} w={'40%'}>
                      <IconNotebook
                        size={20}
                        color="#757575"
                        style={{ flexShrink: 0 }}
                      />
                      <Tooltip label={documentData?.filename}>
                        <Text size="16px" c="#757575" truncate="end">
                          {documentData?.filename}
                        </Text>
                      </Tooltip>
                    </Group>
                    <Group gap="xs">
                      {snapshotVersions?.length > 1 && (
                        <SelectSnapshotVersions
                          selectedSnapshot={selectedSnapshot?.id?.toString()}
                          snapshotVersions={snapshotVersions}
                          setSnapshotVersions={setSnapshotVersions}
                          setSelectedSnapshot={setSelectedSnapshot}
                          primarySnapshot={documentData?.favorite_snapshot}
                          selectViewType="menu"
                        />
                      )}
                      <MoreMenu />
                    </Group>
                  </Flex>
                </Box>
                <FileViewer
                  fileUrl={documentData?.favorite_snapshot?.file || ''}
                  textCoords={textCoords}
                  mode={FileViwerMode.EDIT}
                  annotationConfig={{
                    documentSnaphostId: documentData?.favorite_snapshot?.id,
                    assessmentId: assessmentData?.id,
                  }}
                  {...(showComments && {
                    commentsConfig: {
                      enableComments: true,
                      mode: Mode.EDIT,
                    },
                  })}
                />
              </Grid.Col>
              <Grid.Col className={classes.docInfoBox} span={6}>
                <TabsContainer
                  documentData={documentData}
                  assessmentData={assessmentData}
                  setAssessmentData={setAssessmentData}
                  reRunAssessment={reRunAssessment}
                  fetchDocument={fetchDocument}
                  setTextCoords={setTextCoords}
                  toggleComments={toggleComments}
                />
              </Grid.Col>
            </Grid>
          </AppShell.Main>
        </>
      ) : loading ? null : (
        <NotFound
          title="Not Found"
          description={`Page you are trying to open does not exist. You may have mistyped the url, or the Snapshot document doesn't exists. If you think this is an error contact support.`}
          action={
            <Button onClick={() => navigate('/documents')}>
              Go Back to Documents
            </Button>
          }
        />
      )}
      {showCompareModal && (
        <CompareSnapshotModal
          opened={showCompareModal}
          close={onSnapshotPreviewClose}
          snapshot={selectedSnapshot}
          fetchAssessment={fetchAssessment}
          snapshotVersions={snapshotVersions}
          primarySnapshot={documentData?.favorite_snapshot}
          setSelectedSnapshot={setSelectedSnapshot}
          refreshAssessment={refetchAssessment}
          setSnapshotVersions={setSnapshotVersions}
        />
      )}
    </AppShell>
  );
};

export default Assessment;
