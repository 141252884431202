import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Text,
  Stack,
  Center,
  Accordion,
  useMantineTheme,
  Divider,
} from '@mantine/core';
import { FiltersType } from 'Types/commonTypes';
import { RuleWithEvalType, RuleType, RuleConfig } from 'Types/ruleTypes';
import { showErrorNotification } from 'Utils/notifications';

import RuleItem from './RuleItem';
import SkeletonItem from './RuleSkeleton';

interface ListRulesProps {
  rules: RuleType[];
  loading: boolean;
  selectedRules: RuleType[];
  onRuleSelect: (rule: RuleConfig) => void;
  fetchMoreRules: (filters: FiltersType, showMore?: boolean) => void;
  hasMoreRules: boolean;
}

const ListRules: React.FC<ListRulesProps> = ({
  loading,
  rules,
  selectedRules,
  onRuleSelect,
  fetchMoreRules,
  hasMoreRules,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const observerTarget = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useMantineTheme();
  const addedRules = rules.filter((item: RuleWithEvalType) => item.hasRuleEval);
  const addedRuleIds = new Set(addedRules.map((rule) => rule.id));
  const selectedRuleIds = new Set(selectedRules.map((rule) => rule.id));
  const rulesWithoutEval = rules.filter(
    (item: RuleWithEvalType) => !addedRuleIds.has(item.id)
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (
          entries[0].isIntersecting &&
          hasMoreRules &&
          !loadingMore &&
          !loading
        ) {
          setLoadingMore(true);
          try {
            await fetchMoreRules({}, true);
          } catch (error: any) {
            showErrorNotification(error.message || 'Error fetching more rules');
          } finally {
            setLoadingMore(false);
          }
        }
      },
      {
        root: containerRef.current,
        threshold: 0.1,
        rootMargin: '100px',
      }
    );

    const currentObserverTarget = observerTarget.current;
    if (currentObserverTarget) {
      observer.observe(currentObserverTarget);
    }

    return () => {
      if (currentObserverTarget) {
        observer.unobserve(currentObserverTarget);
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hasMoreRules, loading, loadingMore]);

  return (
    <Box
      mb={16}
      style={{ overflowY: 'auto' }}
      w="100%"
      pos="relative"
      ref={containerRef}
      mah="50vh"
    >
      <SkeletonItem loading={loading} />

      {addedRules.length > 0 && (
        <Accordion
          mb="md"
          style={{
            backgroundColor: theme.colors.gray[0],
            marginBottom: '16px',
            borderRadius: '4px',
          }}
        >
          <Accordion.Item value="selected-rules">
            <Accordion.Control>
              <Text size="sm">{`${addedRules.length} Selected rules`}</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Stack
                gap="xs"
                style={{
                  backgroundColor: theme.colors.gray[0],
                  overflowY: 'auto',
                }}
                mah="30vh"
              >
                {addedRules.map((item: RuleWithEvalType, index: number) => (
                  <React.Fragment>
                    <RuleItem
                      key={item.id}
                      item={item}
                      alreadySelected={true}
                      onRuleSelect={onRuleSelect}
                    />
                    {index !== addedRules.length - 1 && (
                      <Divider mb={12} mt={8} />
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}

      {!loading && (
        <Stack gap="xs">
          {rulesWithoutEval.map((item: RuleWithEvalType, index: number) => {
            const alreadySelected = selectedRuleIds.has(item.id);
            return (
              <React.Fragment>
                <RuleItem
                  key={item.id}
                  item={item}
                  alreadySelected={alreadySelected}
                  onRuleSelect={onRuleSelect}
                />
                {index !== rulesWithoutEval.length - 1 && (
                  <Divider mb={12} mt={8} />
                )}
              </React.Fragment>
            );
          })}
          {loadingMore && <SkeletonItem loading={true} />}
          <div ref={observerTarget} style={{ height: '1px' }} />
        </Stack>
      )}
      {!loading && rules.length === 0 && (
        <Center>
          <Text size="sm">No Rules to show</Text>
        </Center>
      )}
    </Box>
  );
};

export default ListRules;
