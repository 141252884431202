import React, {
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

import { Box, Flex, Text, Button, useMantineTheme } from '@mantine/core';
import AssessmentService from 'Api/assessmentService';
import RuleService from 'Api/ruleService';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { RULE_TAG_KEY } from 'Src/constants';
import useLoading from 'Src/hooks/useLoading';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { DocumentDataType } from 'Types/docTypes';
import {
  RuleEvalType,
  RuleType,
  RuleIdWithResetStatus,
  RuleWithEvalType,
  RuleResponseType,
  RuleFiltersType,
} from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
  showLoadingNotification,
} from 'Utils/notifications';

import ListRules from './ListRules';
import SearchWithFilters from './SearchWithFilters';

interface RuleSetupTab {
  documentData: DocumentDataType;
  assessmentData: AssessmentDetailType | null;
  changeActiveTab: (tab: string) => void;
  setAssessmentData: Dispatch<SetStateAction<AssessmentDetailType | null>>;
}

const initialRulesResponse = {
  items: [],
  count: 0,
};

const PAGE_SIZE = 100;

const anyNewRuleSelected = (selectedRules: RuleType[], ruleEvals: number[]) => {
  if (!selectedRules.length) return false;
  const ruleIds = selectedRules.map((item: RuleType) => item.id);
  return ruleIds.some((id) => !ruleEvals.includes(id));
};

const sortRulesBySelectedStatus = (
  rules: RuleType[],
  selectedRules: RuleType[]
) => {
  const selectedRuleIds = new Set(selectedRules.map((rule) => rule.id));
  return rules.sort((a, b) => {
    if (selectedRuleIds.has(a.id) && !selectedRuleIds.has(b.id)) return -1;
    if (!selectedRuleIds.has(a.id) && selectedRuleIds.has(b.id)) return 1;

    return 0;
  });
};

const RuleSetupTab: React.FC<RuleSetupTab> = ({
  assessmentData,
  changeActiveTab,
  documentData,
  setAssessmentData,
}) => {
  const [loading, handleLoading] = useLoading(false);
  const [runningAssessment, handleRunningAssessment] = useLoading(false);
  const [filteredRules, setFilteredRules] =
    useState<RuleResponseType>(initialRulesResponse);
  const [selectedRules, setSelectedRules] = useState<RuleType[]>([]);
  const [ruleEvals, setRuleEvals] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<RuleFiltersType>({});
  const [fetchingAllRules, handeFetchingAllRules] = useLoading(false);
  const theme = useMantineTheme();
  const hasMoreRules = Math.ceil(filteredRules.count / PAGE_SIZE) > currentPage;
  const assessmentExists = Boolean(
    assessmentData?.report_id && assessmentData?.id
  );
  const submitButtonLabel = assessmentExists
    ? 'Add and Re-run Assessment'
    : 'Add and Run assessment';
  const enableAssessmentButton = useMemo(
    () => anyNewRuleSelected(selectedRules, ruleEvals),
    [selectedRules, ruleEvals]
  );

  const getNoOfSelectedRules = useCallback(
    () =>
      selectedRules.filter((item) =>
        filteredRules.items.map((i) => i.id).includes(item.id)
      ).length,
    [selectedRules, filteredRules.items]
  );

  const fetchAllRules = async (filters: RuleFiltersType) => {
    let page = currentPage + 1;
    let hasMore = hasMoreRules;
    const allFetchedRules: RuleType[] = [];

    handeFetchingAllRules.start();
    try {
      while (hasMore) {
        const response = await RuleService.getRules({
          filters: { ...filters, page },
        });
        if (response && response.data) {
          const { results = [], count } = response.data;
          allFetchedRules.push(...results);
          hasMore = Math.ceil(count / 100) > page;
          page++;
        } else {
          hasMore = false;
        }
      }
    } catch (error) {
      /* silencing the error */
      console.log('An error occurred while fetching rules');
    } finally {
      handeFetchingAllRules.stop();
    }

    return { allRules: [...filteredRules.items, ...allFetchedRules], page };
  };

  const onRuleSelect = useCallback(
    async (ruleConfig: {
      addAll?: boolean;
      removeAll?: boolean;
      ruleToAdd?: object;
    }) => {
      const { addAll, removeAll, ruleToAdd } = ruleConfig;

      if (addAll) {
        if (hasMoreRules) {
          const { allRules, page } = await fetchAllRules(selectedFilters);
          updateRulesWithEvaluationsStatus({
            newRules: allRules,
            currentRuleEvals: ruleEvals,
            totalCount: filteredRules.count,
            allSelected: true,
          });
          setCurrentPage(page);
        } else {
          const sortedRules = sortRulesBySelectedStatus(
            filteredRules.items,
            selectedRules
          );
          setSelectedRules(sortedRules.filter((item) => item.is_active));
        }
      } else if (removeAll) {
        setSelectedRules(
          selectedRules.filter((item: RuleWithEvalType) => item.hasRuleEval)
        );
      } else {
        const currentRule = ruleToAdd as RuleType;
        const isSelected = selectedRules.some(
          (item) => item.id === currentRule.id
        );
        setSelectedRules(
          isSelected
            ? selectedRules.filter((rule) => rule.id !== currentRule.id)
            : [...selectedRules, currentRule]
        );
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [filteredRules, selectedRules, fetchAllRules]
  );

  const saveRulesAndCreateAssessment = async () => {
    showLoadingNotification('Creating Assessment', 'Please wait...');
    try {
      handleRunningAssessment.start();
      if (documentData === null) return;
      const rulesToSend: RuleIdWithResetStatus = selectedRules.reduce(
        (acc, rule) => {
          acc[rule.id] = { reset_status: true };
          return acc;
        },
        {} as RuleIdWithResetStatus
      );
      const { batch_id: batchId, id: docId, favorite_snapshot } = documentData;
      const snapshotIdToUse = favorite_snapshot.id;
      const { data } = await AssessmentService.createAssessments({
        batch_id: batchId,
        document_id: docId,
        document_snapshot_id: snapshotIdToUse,
        reparse: false,
        rules:
          rulesToSend ||
          filteredRules.items.map((item) => ({
            [item.id]: { reset_status: true },
          })),
      });
      if (data && data.id) {
        setAssessmentData(data);
      }
      changeActiveTab('assessment');
    } catch (e: any) {
      showErrorNotification(e.message);
    } finally {
      handleRunningAssessment.stop();
    }
  };

  const runAssessment = async () => {
    try {
      showInfoNotification(
        'Re running the assessment',
        'Evaluation will be updated soon...'
      );
      handleRunningAssessment.start();
      if (!assessmentData) return;
      const rulesToSend: RuleIdWithResetStatus = selectedRules.reduce(
        (acc, rule) => {
          acc[rule.id] = { reset_status: true };
          return acc;
        },
        {} as RuleIdWithResetStatus
      );
      const { data } = await AssessmentService.reinitializePolicyEvals(
        assessmentData?.id,
        rulesToSend
      );
      if (data && data.id) {
        setAssessmentData(data);
        changeActiveTab('assessment');
      } else {
        throw new Error('Something went wrong while re-running assessment');
      }
    } catch (e) {
      showErrorNotification('Something went wrong while re-running assessment');
    } finally {
      handleRunningAssessment.stop();
    }
  };

  const searchRule = async (text: string) => {
    if (!text || text.length == 0) {
      fetchRules();
      return;
    }
    handleLoading.start();
    try {
      const response = await RuleService.searchRule(text);
      if (response) {
        const count = response.data ? response.data?.length : 0;
        updateRulesWithEvaluationsStatus({
          newRules: response.data || [],
          currentRuleEvals: ruleEvals,
          totalCount: count,
        });
      }
    } catch (e) {
      showErrorNotification('Something went wrong while performing search');
    } finally {
      handleLoading.stop();
    }
  };

  const updateRulesWithEvaluationsStatus = (props: {
    newRules: RuleType[];
    currentRuleEvals: number[];
    totalCount: number;
    allSelected?: boolean;
  }) => {
    const { newRules, currentRuleEvals, totalCount, allSelected } = props;
    const allNewRules = newRules.map((item: RuleType) => ({
      ...item,
      hasRuleEval: currentRuleEvals.includes(item.id),
    }));
    const sortedRules = sortRulesBySelectedStatus(allNewRules, selectedRules);
    setFilteredRules({
      items: sortedRules,
      count: totalCount,
    });

    if (allSelected)
      setSelectedRules(sortedRules.filter((item) => item.is_active));
    else {
      const selectedRulesIds = selectedRules.map((i) => i.id);
      const addedRules = allNewRules.filter(
        (item: RuleWithEvalType) =>
          item.hasRuleEval || selectedRulesIds.includes(item.id)
      );
      const newSelectedRules = addedRules.filter(
        (item) => !selectedRulesIds.includes(item.id)
      );
      setSelectedRules((prevSelectedRules) => [
        ...prevSelectedRules,
        ...newSelectedRules,
      ]);
    }
  };

  const fetchRules = async (
    newFilters?: RuleFiltersType,
    fetchingMoreRules = false
  ) => {
    const page = fetchingMoreRules ? currentPage + 1 : 1;
    const filtersToUse = newFilters
      ? { ...selectedFilters, ...newFilters, page }
      : { page };

    if (!fetchingMoreRules) {
      handleLoading.start();
    }

    try {
      let currentRuleEvals = ruleEvals;
      if (!ruleEvals.length) {
        if (assessmentData && assessmentData.report_id && assessmentData.id) {
          const data = await fetchAssessmentDetail();
          currentRuleEvals = data.rule_evals.map(
            (item: RuleEvalType) => item.rule.id
          );
        }
        setRuleEvals(currentRuleEvals);
      }

      /* Specifically handling the All Rules filter */
      const transformedFilters: RuleFiltersType = { ...filtersToUse };
      if (RULE_TAG_KEY in filtersToUse) {
        transformedFilters[RULE_TAG_KEY] = (
          filtersToUse[RULE_TAG_KEY] as string[]
        ).map((tag) => (tag === 'All Rules' ? '' : tag));
      }

      const response = await RuleService.getRules({
        filters: transformedFilters,
      });
      if (response && response.data) {
        const { results = [], count } = response.data;
        let updatedRules = fetchingMoreRules
          ? [...filteredRules.items, ...results]
          : results;
        let latestPage = page;

        // Fetch next set of rules if the current rule evals is more than pageSize
        if (!fetchingMoreRules) {
          while (Math.ceil(currentRuleEvals.length / PAGE_SIZE) > latestPage) {
            latestPage = latestPage + 1;
            const response = await RuleService.getRules({
              filters: { ...transformedFilters, page: latestPage },
            });
            if (response?.data) {
              const { results = [] } = response.data;
              updatedRules = [...updatedRules, ...results];
            }
          }
        }

        updateRulesWithEvaluationsStatus({
          newRules: updatedRules,
          currentRuleEvals,
          totalCount: count,
        });
        setCurrentPage(latestPage);
        setSelectedFilters(filtersToUse);
      }
    } catch (e) {
      showErrorNotification('Something went wrong while fetching rules');
    } finally {
      handleLoading.stop();
    }
  };

  const fetchAssessmentDetail = async () => {
    try {
      const assessmentId = assessmentData?.id;
      if (!assessmentId)
        return showErrorNotification('Invalid Assessment data');
      const response = await AssessmentService.getAssessmentsById(assessmentId);
      if (response) {
        const { data } = response;
        if (data.report) {
          return data.report;
        }
      } else {
        showErrorNotification(
          'Something went wrong while fetching assessment detail'
        );
      }
    } catch (error) {
      showErrorNotification(
        'Something went wrong while fetching assessment detail'
      );
    }
  };

  return (
    <Flex w={'100%'} direction="column" mih="85vh">
      <Flex flex={1} pos="relative" direction="column">
        <Flex
          pt="md"
          pb="xs"
          px="xl"
          direction="column"
          pos="sticky"
          top="0"
          style={{ zIndex: 99 }}
          bg="white"
        >
          <Text mb="xs" size="lg" fw={500}>
            Select rules to run assessment
          </Text>
          <SearchWithFilters
            onRuleSelect={onRuleSelect}
            noOfSelectedRules={getNoOfSelectedRules()}
            rules={filteredRules}
            fetchRules={fetchRules}
            searchRule={searchRule}
            defaultFilters={{ is_active: true }}
            loading={loading}
          />
        </Flex>
        <Box
          px="xl"
          style={{ transition: 'transform 0.5s ease-in' }}
          pos="relative"
        >
          <LoadingOverlay visible={fetchingAllRules} />
          <ListRules
            loading={loading}
            rules={filteredRules.items}
            onRuleSelect={onRuleSelect}
            selectedRules={selectedRules}
            fetchMoreRules={fetchRules}
            hasMoreRules={hasMoreRules}
          />
        </Box>
      </Flex>
      <Flex pb="sm" px="xl" w="100%" bottom="0" bg="white" pos="sticky">
        <Button
          color={theme.colors.tertiary[5]}
          size="lg"
          fullWidth
          onClick={() =>
            assessmentExists ? runAssessment() : saveRulesAndCreateAssessment()
          }
          disabled={!enableAssessmentButton}
          loading={runningAssessment}
        >
          <Text size="sm" fw={700}>
            {submitButtonLabel}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default RuleSetupTab;
