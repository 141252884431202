import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { Grid, Flex, Text, Divider, ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import AssessmentService from 'Api/assessmentService';
import SnapshotService from 'Api/snapshotService';
import Drawer from 'Components/Drawer/Drawer';
import FileViewer from 'Components/file-viewer/FileViewer';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { SnapshotDisplayMode } from 'Src/constants/index';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { DocumentSnapshotType } from 'Types/docTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

import classes from '../Assessment.module.scss';
import SelectSnapshotVersions from '../components/SelectSnapshotVersions';
import SnapshotActions from '../components/compare-snapshots/SnapshotActions';
import VersionsSwitch from '../components/compare-snapshots/VersionsSwitch';
import DisplayRuleEvals from './DisplayRuleEvals';

type CompareSnapshotModalProps = {
  opened: boolean;
  close: () => void;
  snapshot?: DocumentSnapshotType;
  primarySnapshot?: DocumentSnapshotType;
  fetchAssessment: (
    id: number,
    callback: Dispatch<SetStateAction<AssessmentDetailType | null>> | null
  ) => Promise<any>;
  snapshotVersions: DocumentSnapshotType[];
  setSelectedSnapshot: (value?: DocumentSnapshotType) => void;
  setSnapshotVersions: (value: DocumentSnapshotType[]) => void;
  refreshAssessment: () => void;
};

const getVersionsToCompare = (
  currentSnapshot?: DocumentSnapshotType,
  primarySnapshot?: DocumentSnapshotType
) => {
  if (!currentSnapshot || !primarySnapshot) return [];
  return [
    {
      label: `${transformDateString(primarySnapshot.created_at, true)} (Primary)`,
      value: primarySnapshot?.id.toString(),
    },
    {
      label: transformDateString(currentSnapshot.created_at, true),
      value: currentSnapshot?.id.toString(),
    },
  ];
};

const CompareSnapshotModal: React.FC<CompareSnapshotModalProps> = ({
  opened,
  close,
  snapshot,
  fetchAssessment,
  snapshotVersions,
  primarySnapshot,
  setSelectedSnapshot,
  setSnapshotVersions,
  refreshAssessment,
}) => {
  const [snapshotDisplayMode, setSnapshotDisplayMode] = useState(
    SnapshotDisplayMode.PREVIEW
  );
  const [assessmentData, setAssessmentData] = useState<Record<
    string,
    AssessmentDetailType
  > | null>(null);
  const [loading, handleLoading] = useDisclosure(false);
  const [currentSnapshot, setCurrentSnapshot] = useState(snapshot);
  const varsionsToCompare = getVersionsToCompare(snapshot, primarySnapshot);
  const ruleEvals =
    (assessmentData &&
      currentSnapshot &&
      assessmentData[currentSnapshot?.id]?.report?.rule_evals) ||
    [];

  const onClosePreview = () => {
    setSnapshotDisplayMode(SnapshotDisplayMode.PREVIEW);
    close();
  };

  const onCompareSnapshots = () => {
    setCurrentSnapshot(primarySnapshot);
    setSnapshotDisplayMode(SnapshotDisplayMode.COMPARE);
  };

  const onVersionsSwitch = (id: string) => {
    const newSnapshot =
      Number(id) === primarySnapshot?.id ? primarySnapshot : snapshot;
    setCurrentSnapshot(newSnapshot);
  };

  const refetchAssessment = () => {
    onClosePreview();
    refreshAssessment();
  };

  const makeItPrimary = async () => {
    if (!currentSnapshot?.id) return;
    try {
      await SnapshotService.markSnapshotAsPrimary(currentSnapshot?.id, true);
      setSelectedSnapshot(currentSnapshot);
      refetchAssessment();
    } catch (e: any) {
      showErrorNotification(
        e.message || 'Error making the snapshot as primary'
      );
    }
  };

  const fetchAssessmentdetails = async (id: number) => {
    handleLoading.open();
    try {
      if (assessmentData && assessmentData[id]) return;
      const assessment: AssessmentDetailType = await fetchAssessment(id, null);
      if (!assessment?.id) {
        return setAssessmentData(assessmentData);
      }
      const response = await AssessmentService.getAssessmentsById(
        assessment.id
      );
      if (response) {
        const { data } = response;
        const assessment = { [id]: data, ...assessmentData };
        setAssessmentData(assessment);
      }
    } catch (error) {
      showErrorNotification(
        'Something went wrong while fetching assessment detail'
      );
    } finally {
      handleLoading.close();
    }
  };

  const updateSnapshotVersions = (deletedSnapshotId: string) => {
    const updatedSnapshots = snapshotVersions.filter(
      (snapshot) => snapshot.id.toString() !== deletedSnapshotId
    );
    setSnapshotVersions(updatedSnapshots);
    refetchAssessment();
  };

  useEffect(() => {
    if (snapshot?.id) {
      setCurrentSnapshot(snapshot);
      setSnapshotDisplayMode(SnapshotDisplayMode.PREVIEW);
    }
  }, [snapshot]);

  useEffect(() => {
    if (currentSnapshot?.id) fetchAssessmentdetails(currentSnapshot?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSnapshot?.id]);

  if (!snapshot) return;

  return (
    <Drawer
      opened={opened}
      onClose={onClosePreview}
      size="85%"
      position="right"
      withinPortal={false}
      keepMounted={false}
      withCloseButton={false}
      lockScroll
      className={classes.drawer}
      offsetFromHeader
    >
      <Drawer.Body p="md">
        <Grid p="0" pos="relative">
          <LoadingOverlay visible={loading} />
          <Grid.Col span={7}>
            <Flex direction="column" pr="md">
              <Flex mb="sm" justify="space-between" w="100%">
                {snapshotDisplayMode === SnapshotDisplayMode.COMPARE ? (
                  <React.Fragment>
                    <VersionsSwitch
                      versions={varsionsToCompare}
                      onChange={onVersionsSwitch}
                      primarySnapshot={primarySnapshot}
                    />
                    <SelectSnapshotVersions
                      selectedSnapshot={snapshot.id?.toString()}
                      snapshotVersions={snapshotVersions}
                      setSnapshotVersions={setSnapshotVersions}
                      setSelectedSnapshot={setSelectedSnapshot}
                      showActions={false}
                      selectViewType="select"
                      primarySnapshot={primarySnapshot}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <SnapshotActions
                      primarySnapshotId={primarySnapshot?.id}
                      snapshotId={snapshot?.id}
                      makeItPrimary={makeItPrimary}
                      compareSnapshots={onCompareSnapshots}
                      position={'header'}
                      close={onClosePreview}
                      updateSnapshotVersions={updateSnapshotVersions}
                    />
                  </React.Fragment>
                )}
              </Flex>
              {/* Pdf */}
              <FileViewer
                height="82vh"
                fileUrl={currentSnapshot?.file || snapshot?.file}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <Flex direction="column">
              <Flex py="md" gap="xs" justify={'space-between'} align={'center'}>
                {currentSnapshot?.created_at && (
                  <Text size="md">
                    {transformDateString(currentSnapshot.created_at, true)}
                  </Text>
                )}
                <ActionIcon
                  variant="subtle"
                  color="black"
                  onClick={onClosePreview}
                >
                  <IconX stroke={1.5} />
                </ActionIcon>
              </Flex>
              <Divider />
              <Flex
                mt="md"
                h="75vh"
                mb="lg"
                gap="sm"
                direction="column"
                style={{ overflowY: 'auto' }}
              >
                <DisplayRuleEvals
                  loading={loading}
                  ruleEvals={ruleEvals}
                  viewMode={true}
                  snapshotId={currentSnapshot?.id}
                />
              </Flex>
              <SnapshotActions
                primarySnapshotId={primarySnapshot?.id}
                snapshotId={currentSnapshot?.id}
                makeItPrimary={makeItPrimary}
                compareSnapshots={onCompareSnapshots}
                position={'footer'}
                close={onClosePreview}
                updateSnapshotVersions={updateSnapshotVersions}
                snapshotDisplayMode={snapshotDisplayMode}
              />
            </Flex>
          </Grid.Col>
        </Grid>
      </Drawer.Body>
    </Drawer>
  );
};

export default CompareSnapshotModal;
