import { DataTableColumn } from 'mantine-datatable';

import { Badge, Text, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import TableWithFilters from 'Components/data-table/TableWithFilters';
import { RuleFilters } from 'Constants/index';
import { FiltersType } from 'Src/types/commonTypes';
import { RuleType } from 'Src/types/ruleTypes';
import { transformDateString } from 'Src/utils/transform';

type ListRulesProps = {
  rules: RuleType[];
  openFilledDrawer: (id: number) => void;
  onPageChange: (page: number) => void;
  loadingRules: boolean;
  totalRules: number;
  page: number;
  onSearch: (value: string) => void;
  onApplyFilters?: (filters: FiltersType) => void;
};

const ListRules: React.FC<ListRulesProps> = ({
  rules = [],
  openFilledDrawer,
  onPageChange,
  loadingRules,
  totalRules,
  page,
  onSearch,
  onApplyFilters,
}) => {
  const [disableDataTable, handleDisableDataTable] = useDisclosure(false);

  const columns: DataTableColumn<RuleType>[] = [
    {
      accessor: 'title',
      title: 'Title',
      width: '40%',
      render: (rule) => (
        <Text
          size="sm"
          td="underline"
          onClick={() => {
            if (rule && rule.id) {
              openFilledDrawer(rule.id);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {rule?.title}
        </Text>
      ),
    },
    {
      accessor: 'is_active',
      title: 'Status',
      render: (rule) => (
        <Badge color={rule.is_active ? 'green' : 'gray'}>
          {rule.is_active ? 'Active' : 'In-Active'}
        </Badge>
      ),
    },
    {
      accessor: 'updated_by',
      title: 'Updated By',
      render: (rule) => rule?.updated_by?.first_name,
    },
    {
      accessor: 'updated_at',
      title: 'Updated At',
      render: (rule) => transformDateString(rule?.updated_at),
    },
    {
      accessor: 'created_at',
      title: 'Created At',
      render: (rule) => transformDateString(rule?.created_at || ''),
    },
  ];

  return (
    <Flex direction="column" w="100%" h="80vh">
      <TableWithFilters
        loading={loadingRules}
        records={rules}
        columns={columns}
        totalRecords={totalRules}
        page={page}
        onPageChange={(currentPage: number) => {
          onPageChange(currentPage);
        }}
        disableDataTable={disableDataTable}
        showSearch
        onSearch={onSearch}
        showFilterIcon
        onToggleFilters={handleDisableDataTable.toggle}
        onApplyFilters={onApplyFilters}
        toolBarConfig={{
          filtersConfig: RuleFilters,
        }}
      />
    </Flex>
  );
};

export default ListRules;
